import React, { Component } from 'react'

export default class NoMatch extends Component {
  render() {
    return (
      <div style={{textAlign: `center`}}>
          <h1>404 - Página não encontrada!</h1>
      </div>
    )
  }
}
